import React from 'react';

function SalesContractList(props) {
    return (
        <div className="sales-contract-list">
            {props.salesContracts.map(contract => (
                <div className="sales-contract-card" key={contract.id}>
                    <div className="contract-field"><strong>ID:</strong> {contract.id}</div>
                    <div className="contract-field"><strong>Owner:</strong> {contract.owner}</div>
                    <div className="contract-field"><strong>Last Deposit Time:</strong> {contract.lastDepositTime}</div>
                    <div className="contract-field"><strong>Asset Address:</strong> {contract.asset}</div>
                    <div className="contract-field"><strong>Draft ID:</strong> {contract.draftId}</div>
                    <div className="contract-field"><strong>Deposit/Recalibration:</strong> {contract.depositPerRecalibration}</div>
                    <div className="contract-field"><strong>Highest Asset Value:</strong> {contract.highestAssetValue}</div>
                    <div className="contract-field"><strong>Duration:</strong> {contract.duration}</div>
                    <div className="contract-field"><strong>GOV Stake/Recalibration:</strong> {contract.govStakePerRecalibration}</div>
                    <div className="contract-field"><strong>Reward Sum:</strong> {contract.rewardSum}</div>
                </div>
            ))}
        </div>
    );
}

SalesContractList.defaultProps = {
    salesContracts: [
      {
        id: 1,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 2,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      },
      {
        id: 3,
        owner: '0x0...123',
        lastDepositTime: 1618329021,
        asset: '0x0...abc',
        draftId: 101,
        depositPerRecalibration: 500,
        highestAssetValue: 10000,
        startRecalibration: 1618229021,
        duration: 30,
        govStakePerRecalibration: 100,
        lastRewardPayout: 1618429021,
        totalGovStaked: 1000,
        rewardSum: 15000,
      }
    ],
  };

export default SalesContractList;
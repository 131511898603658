import 'react-bootstrap';
import 'bootstrap';
import './style/explaination-sub-point-style.scss';


function ExplainedCard({text, icon, height, marginLeft, iconScale}) { 
    

    return (
        <div className='explained-card'>
            <img className='explained-card-icon' src={icon? icon :'images/circle.svg'} style={{transform: `scale(${iconScale})`, height: height, marginLeft: marginLeft}}/>
            <p>{text}</p>
        </div>
);
}

ExplainedCard.defaultProps = {
    iconScale: '0.6',
    height: '32px',
    text: 'Utilize a cool thing to do amazing stuff.',
    marginLeft: '0px',
};


export default ExplainedCard;
import 'react-bootstrap';
import 'bootstrap';
import HeroLanding from './HeroLanding';
import Team from './Team';
import { useEffect } from 'react';
import EasyExplaination from './EasyExplaination';
import OwnManageGovern from './OwnManageGovern';
import ReadTheWhitepaper from './ReadTheWhitepaper';
import Discord from './Discord';
import ForDevelopers from './ForDevelopers';
import UseCompeteGovern from './UseCompeteGovern';

export default function Home() {     

    useEffect(() => {
        const onScroll = () => {
          const hiddenElements = document.querySelectorAll('.transition-hide');
          hiddenElements.forEach(el => {
            const Y_OFFSET = (4 * (window.innerHeight/5));
            const elementRect = el.getBoundingClientRect();
            const isVisible = elementRect.top < Y_OFFSET;
    
            if (isVisible) {
              el.classList.add('transition-show');
            } else {
              // Optionally remove the class if it should only show once the condition is met
              // el.classList.remove('transition-show');
            }
          });
        };
    
        // Throttle scroll events to prevent performance issues
        let throttleTimeout = null;
        const throttle = (callback, time) => {
          if (throttleTimeout) return;
    
          throttleTimeout = setTimeout(() => {
            callback();
            throttleTimeout = null;
          }, time);
        };
    
        // Listen for scroll events
        window.addEventListener('scroll', () => throttle(onScroll, 50));
    
        // Cleanup the listener on component unmount
        return () => {
          if (throttleTimeout) clearTimeout(throttleTimeout);
          window.removeEventListener('scroll', onScroll);
        };
      }, []); // Empty array ensures this effect is only run on mount and unmount
    


    return (
    <div>
        <HeroLanding/>
        <EasyExplaination/>
        <UseCompeteGovern/>
        <ReadTheWhitepaper/>
        <Discord/>
        {/*<Team/>*/}
        <div style={{height: '64px'}}></div>
        <ForDevelopers/>
        

        {/*   
        
        */}
      
    </div>       
    );
}

import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
    
function FundDistributionChart({ fundData, setHoveredTime, hoveredTime }) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    const startHue = 200; // cyan
    const endHue = 290; // purple
    const numberOfColors = 10;

    const hueStep = (endHue - startHue) / (numberOfColors - 1);
    const hues = [];

    for (let i = 0; i < numberOfColors; i++) {
        hues.push(startHue + i * hueStep);
    }

    const labels = fundData.map(data=> data.date);
    const datasets = Object.keys(fundData[0])
        .filter(key => key !== 'date')
        .map((assetKey, index) => ({
            label: assetKey,
            data: fundData.map(data => data[assetKey]),
            fill: true,
            backgroundColor: `hsla(${hues[index]}, 60%, 70%, 0.5)`, // semi-transparent fill
            borderColor: `hsl(${hues[index]}, 100%, 50%)`,
            tension: 0.4, // for quadratic bezier curves
        }));

    const data = {
        labels,
        datasets,
    };

    const options = {
        maintainAspectRatio: false,

        onHover: (event, elements) => {
            if (elements && elements.length > 0) {
              // Get the index of the dataset (assuming there's only one dataset in your case)
              const datasetIndex = elements[0].datasetIndex;
              // Get the X-axis index of the hovered point
              const index = elements[0].index;
              // Get the corresponding time value
              const hoveredTimeValue = data.labels[index]; // Corrected access to time value
              // Update the state with the hovered time value
              setHoveredTime(hoveredTimeValue);
            } else {
            }
          },

        animation: {
            duration: 500, // Duration in milliseconds (e.g., 2000ms for 2 seconds)
            easing: 'easeOutQuart', // Easing function (e.g., 'easeInOutQuart')
            animateScale: true,
            animateRotate: true
        },

        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                stacked : true,
                title: {
                    display: true,
                    text: 'Value'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top'
            }
        },
        maintainAspectRatio: false
    };
    
    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy(); // Destroy the previous instance if exists
        }
    
    const ctx = chartRef.current.getContext('2d');
    
    chartInstanceRef.current = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
      width: '200px',
      height: '200px',
    });
    
    return () => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy(); // Cleanup chart instance on unmount
        }
    };
    }, [fundData]); // Re-run effect if fundData changes
    
    return (
        <div className='dashboard-chart' style={{width: 'calc(100% - 20px)', height: '300px', margin: 'auto'}}>
            <p>Hovered Time: {hoveredTime}</p> {/* Display the hovered time */}
            <canvas ref={chartRef}/>
        </div>
        );
}

FundDistributionChart.defaultProps = {
    fundData: [
        { date: '2023-01-01', asset1: 100, asset2: 200 },
        { date: '2023-02-01', asset1: 150, asset2: 180 },
        { date: '2023-03-01', asset1: 130, asset2: 210 },
        { date: '2023-04-01', asset1: 200, asset2: 190 },
        { date: '2023-05-01', asset1: 160, asset2: 220 },
        { date: '2023-06-01', asset1: 170, asset2: 230 },
        { date: '2023-07-01', asset1: 180, asset2: 240 },
        { date: '2023-08-01', asset1: 190, asset2: 250 },
        { date: '2023-09-01', asset1: 200, asset2: 260 },
        { date: '2023-10-01', asset1: 210, asset2: 220 },
        { date: '2023-11-01', asset1: 205, asset2: 215 },
        { date: '2023-12-01', asset1: 220, asset2: 210 },
        { date: '2024-01-01', asset1: 230, asset2: 220 },
        { date: '2024-02-01', asset1: 240, asset2: 230 },
        { date: '2024-03-01', asset1: 250, asset2: 240 },
        { date: '2024-04-01', asset1: 260, asset2: 250 },
        { date: '2024-05-01', asset1: 270, asset2: 260 },
        { date: '2024-06-01', asset1: 280, asset2: 270 },
        { date: '2024-07-01', asset1: 290, asset2: 280 },
        { date: '2024-08-01', asset1: 300, asset2: 290 },
        { date: '2024-09-01', asset1: 310, asset2: 300 },
        { date: '2024-10-01', asset1: 320, asset2: 310 },
        { date: '2024-11-01', asset1: 330, asset2: 320 },
        { date: '2024-12-01', asset1: 340, asset2: 330 },
    ],
  };

export default FundDistributionChart;

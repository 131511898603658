import React, { useState } from 'react';
import { subscribeToMulticoinNewsletter } from './mailchimp_functions';
import { API } from 'aws-amplify';
import awsconfig from './aws-exports';
API.configure(awsconfig);

function Newsletter() { 
    
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        let value = email;
        document.getElementById('newsletter-submit').style.display = 'none';
        document.getElementById('newsletter-submit-loading').style.display = 'block';
        
        const response = await subscribeToMulticoinNewsletter(value, setEmail);
        
        document.getElementById('newsletter-submit').disabled = false;
        console.log("Response: ", response); // This will log the entire response

        document.getElementById('newsletter-submit').style.display = 'block';
        document.getElementById('newsletter-submit-loading').style.display = 'none';

        try {
            alert(response.message);
        }
        catch (error) {
            alert('Subscription Failed');
        }


        
    }

    return (
        <div className="region-start " style={{marginTop: '0px', paddingTop: '60px'}} id='Newsletter'>
            <div className="content flex-vertical rounded-content" style={{paddingLeft: '0px', paddingBottom: '0px'}}> 
               <h3 style={{textAlign: 'left', fontSize: '18px', letterSpacing: '1px', color: '#c0d5ff', fontWeight: '300', marginBottom: '0px', marginTop: '-20px'}}>Newsletter</h3>
                <h3 style={{textAlign: 'left', fontSize: '24px', fontWeight: '900', lineHeight:  '1',maxWidth: '500px',letterSpacing: '-0.5px', color: '#F9F8FE', marginTop: '0px'}}>Sign up to stay up to date with the latest news!</h3>
                <div className="content flex-left" style={{alignitems: 'flex-start', marginLeft: '0px',marginTop: '8px',  padding: '0px', width: 'min(100%, 600px)'}}>
                    <form onSubmit={handleSubmit} className="newsletter_input_wrapper flex-right" style={{width: '2000px'}}>
                        <input type="email" id="newsletter-email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" style={{paddingLeft: '8px'}}/>
                        <div className='newsletter-submit-wrapper'>
                            <input type="submit" id='newsletter-submit' value='Subscribe' title='Subscribe to newsletter' className="newsletter_subscribe_button no-select" style={{textAlign: 'center', fontSize: '16px', width: '148px', height: '38px', padding: '0px', paddingTop: '4px', margin: '4px', letterSpacing: '1px', fontWeight: '300'}}></input>
                            <div className='newsletter_subscribe_button no-select disabled' id='newsletter-submit-loading' style={{display: 'none', textAlign: 'center', fontSize: '16px', width: '148px', height: '38px', padding: '0px', paddingTop: '4px', margin: '4px', letterSpacing: '1px', fontWeight: '300'}}><img className='LoadingIcon' alt='loading...' src='./images/figures/spinning_arrow.png'></img></div>
                        </div>
                        
                    </form>
                </div>
            </div>
            
        </div>
);
}

export default Newsletter;


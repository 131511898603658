import 'bootstrap';
import { useNavigate } from 'react-router-dom';

function NavBar() { 
    
  let navigate = useNavigate();

  function returnHome() {
    navigate('/');
  }

  function goToProtocol() {
    window.scrollTo(0, 0);
    navigate('/explained');
  }

  function goToFAQ() {
    window.scrollTo(0, 0);
    navigate('/faq');
   
  }

return (
  <nav className="navbar navbar-expand-lg header transition-02" id='header' >
    <div className="container-lg transition-02" id='header-2'>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" id='header-toggler'></span>
      </button>
      <div className="collapse navbar-collapse transition-02" id="navbarToggler">
        <ul className="navbar-nav ml-2 mb-lg-0 mr-2 transition-02 container" style={{width: 'fit-content', display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight:'auto'}}>
          <li className="nav-item header-button" >
            <a className="nav-link" onClick={returnHome} href="">
              <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                Home
              </span>
            </a>
          </li>
          <li className="nav-item header-button" >
            <a className="nav-link" id='navbar-toggle-link' onClick={goToProtocol} href=""> 
              <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                Multi Explained
              </span>
            </a>
          </li>
          <li className="nav-item header-button" >
            <a className="nav-link" id='navbar-toggle-link' onClick={goToFAQ} href=""> 
              <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                FAQ
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

);
}

export default NavBar;


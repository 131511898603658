import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function DraftDistribution({ fundDistribution, currentDraftDistribution }) {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    const processData = (data) => {
        const total = Object.keys(data).reduce((sum, key) => sum + data[key], 0);
        const percentageData = {};
        Object.keys(data).forEach(key => {
            percentageData[key] = (data[key] / total) * 100; // Convert to percentage
        });
        return percentageData;
    };

    const fundData = processData(fundDistribution);
    const draftData = processData(currentDraftDistribution);

    const assetKeys = Object.keys(fundData);
    const labels = assetKeys;

    const datasets = assetKeys.map((assetKey, index) => {
        return [
            {
                label: `Fund - ${assetKey}`,
                data: [{ x: assetKey, y: fundData[assetKey] }],
                backgroundColor: `rgba(0, 123, 255, 0.5)`,
                barThickness: 50, // You can adjust this value as needed
            },
            {
                label: `Draft - ${assetKey}`,
                data: [{ x: assetKey, y: draftData[assetKey] }],
                backgroundColor: `rgba(255, 193, 7, 0.5)`,
                barThickness: 50, // Make sure this is the same as above
            }
        ];
    }).flat();

    const data = {
        labels,
        datasets,
    };

    const options = {
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false, // This can help reduce visual noise
                },
            },
            y: {
                stacked: false,
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percentage'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        },
        maintainAspectRatio: false
    };

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');

        chartInstanceRef.current = new Chart(ctx, {
            type: 'bar',
            data,
            options
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [fundDistribution, currentDraftDistribution]);

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <canvas ref={chartRef} />
        </div>
    );
}


DraftDistribution.defaultProps = {
    fundDistribution: {
        asset1: 100,
        asset2: 150,
    },
    currentDraftDistribution: {
        asset1: 90,
        asset2: 160,
    }
};

export default DraftDistribution;

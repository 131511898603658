import 'react-bootstrap';
import 'bootstrap';


export default function WaveHeader({
    text='',
    size = 'NULL',
    center='false',
    ...rest}) { 
    
    const style = center === 'true' ? {
        zIndex: '3000000000000000000000000',
        position: 'relative', 
        top: '0px', 
        marginLeft : 'auto', 
        marginRight : 'auto', 
        overflow: 'visible'
    } : {
        position: 'relative', 
        top: '0px', 
        marginLeft : '0px', 
        overflow: 'visible'
    };

    const fontStyle = size === 'NULL' ? { zIndex: '3000000000000000000000000',} : { zIndex: '3000000000000000000000000', fontSize : size}

    return (
        <div className='WaveHaederOuter' style={style}>
            <h1 style={{zIndex: '3000000000000000000', filter: 'drop-shadow(0px 0px 12px #fcfcfc)', textShadow: '-1px 1px 52px rgba(252,252,252,1)', ...fontStyle}} className='WaveHeaderText'>Multi</h1>
            <h1 style={fontStyle} className='WaveHeaderTextOver WaveAnimation'>Multi</h1>
        </div>
    );
}

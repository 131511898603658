import 'react-bootstrap';
import 'bootstrap';
import './style/new_multicoin_style.scss';
import React, { useState, useEffect, useRef } from 'react'; 
import * as THREE from 'three';

function GlobeBackground() { 

    let scene;
    let camera;
    let renderer;
    let texturesToLoadLeft = 4;
    let currentLookAtX = 0;
    let currentLookAtY = 5;
    let currentLookAtZ = 0;
    let targetLookAtX = currentLookAtX;
    let targetLookAtY = currentLookAtY;
    let targetLookAtZ = currentLookAtZ;
    let targetScrollY = window.scrollY;
    let composer;

    const canvasRef = useRef(null);
    const [isWebGLSupported, setIsWebGLSupported] = useState(false);

    // Function to check for WebGL support
    const checkWebGLSupport = () => {
        try {
            let canvas = document.createElement('canvas');
            return !!window.WebGLRenderingContext && 
                   !!(canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
        } catch (e) {
            return false;
        }
    };


    const lerp = (value1, value2, lerpAmount) => {
        return (1 - lerpAmount) * value1 + lerpAmount * value2;
      }

    useEffect(() => {
        
        setIsWebGLSupported(checkWebGLSupport());

        if (!isWebGLSupported) {
            return;
        }

        scene = new THREE.Scene(); 
        camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set( -5, 2.4, 0 );
        camera.lookAt( currentLookAtX, currentLookAtY, currentLookAtZ);
        camera.updateMatrixWorld();

        // Instatitate Renderer
        renderer = new THREE.WebGLRenderer({
            canvas : document.getElementById('3d-globe'),
            antialias : true, 
            alpha : true
        });

  
        renderer.toneMapping = THREE.LinearToneMapping; 
        renderer.gammaFactor = 0.2; // Good default for converting colors to sRGB color space
        renderer.toneMappingExposure = 1.4; 

        const onTextureLoad = () => {
            targetLookAtY = 3.4;
        }
        
        // Use a ref to avoid re-querying the DOM
        const canvas = canvasRef.current;

        // Resize function
        const resizeRendererToDisplaySize = () => {
            const pixelRatio = window.devicePixelRatio;
            const width = canvas.clientWidth * pixelRatio | 0;
            const height = canvas.clientHeight * pixelRatio | 0;
            const needResize = canvas.width !== width || canvas.height !== height;
            if (needResize && isWebGLSupported) {
                // Schedule the resize asynchronously
                requestAnimationFrame(() => {
                    renderer.setSize(width, height, false);
                    renderer.setPixelRatio(pixelRatio);
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();
                });
            }
        };

        function throttle(func, limit) {
            let inThrottle;
            return function() {
                const args = arguments;
                const context = this;
                if (!inThrottle) {
                    func.apply(context, args);
                    inThrottle = true;
                    setTimeout(() => inThrottle = false, limit);
                }
            }
        }
        
        const throttledResize = throttle(resizeRendererToDisplaySize, 100);

        // Create a ResizeObserver to listen for changes in canvas size
        const resizeObserver = new ResizeObserver(entries => {
            // For each entry, call the resize function
            for (let entry of entries) {
            resizeRendererToDisplaySize();
            }
        });
    
        // Re render canvas when zooming
        window.addEventListener('resize', throttledResize);

        // Observe the canvas element
        resizeObserver.observe(canvas, { throttledResize });

        renderer.setPixelRatio( window.devicePixelRatio );
        //renderer.setSize( window.innerWidth, window.innerHeight);

        renderer.render( scene, camera);

        // Earth
        const earthDifuse = new THREE.TextureLoader().load('images/earthDifuse.png', onTextureLoad); 
        const earthNormal = new THREE.TextureLoader().load('images/earthNormal.png', onTextureLoad);
        const earthRoughness = new THREE.TextureLoader().load('images/earthRoughness.png', onTextureLoad);

        const earth = new THREE.Mesh(
            new THREE.SphereGeometry(3, 64, 64),
            new THREE.MeshToonMaterial( {
                color : 0xaaaaaa,
                map : earthDifuse,
                normalMap : earthNormal,
                clearcoat: 0.3,
                fog: true,
                clearcoatRoughness: 0.2,
                normalScale : new THREE.Vector2(1.5, 1.5),
                roughnessMap: earthRoughness,
                roughness : 0.1,
                specularIntensity: 0,
            } )
        );

        

        // Ensure the mesh can cast and receive shadows
        earth.castShadow = true;
        earth.receiveShadow = true;
        
        earth.rotation.x = 0.1;
        earth.rotation.y = 2.0;

        scene.add(earth);

        //const pointLight = new THREE.PointLight(0xffffff, 100);
        //pointLight.position.set(0, 6, 9);
        
        // Rectangular Light
        const width = 20;
        const height = 20;
        const intensity = 1.2;
        const rectLight = new THREE.RectAreaLight( 0xffffff, intensity,  width, height );
        rectLight.position.set( 0, 5, -1 );
        rectLight.lookAt( 0, 0, 0 );
        scene.add( rectLight );
        scene.fog = new THREE.Fog(0x586ce1, 1100, 2000);
        //const rectLightHelper = new THREE.PointLightHelper( rectLight );
        //rectLight.add( rectLightHelper );

        const ambientLight = new THREE.AmbientLight(0xffffff, 1);  

        scene.add(ambientLight);
        
        //const lightHelper = new THREE.PointLightHelper(pointLight);
        //const gridHelper = new THREE.GridHelper(200, 50);
        //scene.add(gridHelper);

        // Orbit controls
        //const controls = new OrbitControls(camera, renderer.domElement);

        const starGeometry = new THREE.SphereGeometry(0.1, 24, 24);
        const starMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });
       
        /*
        const addStar = ()=>{
            const star = new THREE.Mesh( starGeometry, starMaterial);
            
            const [x, y, z] = Array(3).fill().map(() => THREE.MathUtils.randFloatSpread(100, 150));
            star.position.set(x, y, z);
            scene.add(star);
            return star;
        }

        Array(200).fill().forEach(addStar);

        */

        // Scene background
        const spaceTexture = new THREE.TextureLoader().load('images/space.png', onTextureLoad);
        //scene.background = spaceTexture; // Set background
        renderer.setClearColor(0x000000, 0);

        const animate = () => {
            if (isWebGLSupported) {
                let fpsInterval = 1000 / 30; // 30 frames per second
                let then = Date.now();
            
                const animateLoop = () => {
                    if (isWebGLSupported){
                        requestAnimationFrame(animateLoop);
                    }
            
                    let now = Date.now();
                    let elapsed = now - then;
            
                    if (elapsed > fpsInterval) {
                        then = now - (elapsed % fpsInterval);
            
                        earth.rotation.y += 0.0005;
                        currentLookAtX = lerp(currentLookAtX, targetLookAtX, 0.005);
                        currentLookAtY = lerp(currentLookAtY, targetLookAtY, 0.005);
                        currentLookAtZ = lerp(currentLookAtZ, targetLookAtZ, 0.005);
                        camera.lookAt(currentLookAtX, currentLookAtY + targetScrollY, currentLookAtZ);
                        renderer.render(scene, camera);
                    }
                }
                animateLoop();
            }
            
        }
        let animateId = -1;
        if (isWebGLSupported) {
            animateId = requestAnimationFrame(animate);
        }

        return () => {

            window.removeEventListener('resize', throttledResize);

            // Clean up Observer

            // Stop all animations or frame requests
            if (animateId) {
                cancelAnimationFrame(animateId);
            }
        
            // Dispose of scene objects, materials, and textures
            scene.traverse(function (object) {
                if (object.isMesh) {
                object.geometry.dispose();
                if (object.material.isMaterial) {
                    cleanMaterial(object.material);
                } else {
                    // an array of materials
                    for (const material of object.material) cleanMaterial(material);
                }
                }
                // Dispose of lights
                if (object.isLight) {
                object.dispose();
                }
            });
        
            // Clean up materials
            function cleanMaterial(material) {
                for (const key of Object.keys(material)) {
                const value = material[key];
                if (value && typeof value === 'object' && 'dispose' in value) {
                    try{
                        value.dispose();
                    } catch(error){
                        console.log(error);
                    }
                }
                }
            }
        
            // Dispose of the renderer's resources
            renderer.dispose();
        
            // If you're using the OrbitControls
            // controls.dispose();
  
            // Remove resize observer
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
      }, [isWebGLSupported]);

    const handleScroll = () => {
        let scroll = Math.min(window.scrollY, 240);
        targetScrollY = scroll / 200;
    };

    //Scroll Handling
    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Remove scroll event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    if (!isWebGLSupported) {
        return <img src='/images/earthDifuse.png'/>;
    }

    return (
        <canvas className='globeCanvas' ref={canvasRef} style={{width: '130%', marginLeft: '-15%', height: '480px', position: 'relative', bottom: '0px', marginBottom: '0px', marginTop: 'auto'}} id='3d-globe'> </canvas>
    );
}

export default GlobeBackground;
import React from 'react';

function ScrollableBidList({ draftBids, currentUserAddress }) {
  return (
    <div className="scrollable-list-container">
      <div className="header">
        <span className="header-title">Owner</span>
        <span className="header-title">Asset</span>
        <span className="header-title">Deposit per Recalibration</span>
        <span className="header-title">GOV Stake per Recalibration</span>
        <span className="header-title">Duration</span>
        <span className="header-title">Actions</span>
      </div>
      <div className="list-items">
        {draftBids.map((bid, index) => (
          <div className="list-item" key={index}>
            <span className="item-detail">{bid.owner}</span>
            <span className="item-detail">{bid.asset}</span>
            <span className="item-detail">{bid.depositPerRecalibration}</span>
            <span className="item-detail">{bid.govStakePerRecalibration}</span>
            <span className="item-detail">{bid.duration}</span>
            <span className="item-detail">
              {bid.owner === currentUserAddress && (
                <button className="adjust-bid-button">Adjust Bid</button>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

ScrollableBidList.defaultProps = {
    draftBids: [
      {
        owner: '0x123',
        asset: 'Asset A',
        depositPerRecalibration: '100',
        govStakePerRecalibration: '50',
        duration: '30 days',
      },
      {
        owner: '0x456',
        asset: 'Asset B',
        depositPerRecalibration: '150',
        govStakePerRecalibration: '75',
        duration: '45 days',
      },
      {
        owner: '0x789',
        asset: 'Asset C',
        depositPerRecalibration: '200',
        govStakePerRecalibration: '100',
        duration: '60 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
      {
        owner: '0xABC',
        asset: 'Asset D',
        depositPerRecalibration: '250',
        govStakePerRecalibration: '125',
        duration: '90 days',
      },
    ],
    currentUserAddress: '0x456', // This should match one of the owner addresses
  };

export default ScrollableBidList;
import 'react-bootstrap';
import 'bootstrap';
import './style/new_multicoin_style.scss';
import React, { useState } from 'react'; 

const WaveRegion = ({ children, style }) => {
    
    const [componentHeight, setComponentHeight] = useState('200px');
    const scrollingStyle = {
        position: 'absolute',
        bottom: '-4px',
    }

    return (
        <div className={`region story-background transition-show`} id='Components'
             style={{
                 minHeight: componentHeight,
                 height: 'fit-content',
                 paddingTop: '60px',
                 paddingBottom: '128px',
                 backgroundImage: `var(--background-image)`,
                 ...style
             }}>
                {children}
                <div className='scrolling-wrapper' style={scrollingStyle}>
                    <div className='scrolling-content wave-3' style={{mixBlendMode: 'lighten'}}/>
                    <div className='scrolling-content wave-2' style={{mixBlendMode: 'lighten'}}/>
                    <div className='scrolling-content wave-1' style={{mixBlendMode: 'lighten'}}/>
                </div>
        </div>
    );
}

WaveRegion.defaultProps = {
    style : {}
}

export default WaveRegion;
import Login from "./Login";
import DashboardView from "./DashboardView";
import './dashboard_style.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // React Router v6

function Dashboard(props) { 
  const navigate = useNavigate();
  const DEFAULT_USERADDRESS = '0x0000000000000000000000000000000000000000';
  const [userAddress, setUserAddress] = useState(DEFAULT_USERADDRESS);


  useEffect(() => {
    if (window.location.pathname !== "/dashboard/login" && userAddress === DEFAULT_USERADDRESS) {
      navigate('/dashboard/login');
      console.log(window.location);
    }
  });
  

    return (
      <div style={{width: '100%', height: '100vh', backgroundColor: '#f0f0f0'}}>
      <Routes>
        <Route index element={ userAddress === DEFAULT_USERADDRESS ? <Login setUserAddress={setUserAddress}/> : <DashboardView userAddress={userAddress}/>} />
        <Route path="view" element={<DashboardView userAddress={userAddress}/>} />
        <Route path="login" element={<Login setUserAddress={setUserAddress}/>} />
      </Routes>
      <Outlet />
    </div>
    );
}

export default Dashboard;
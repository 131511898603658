import 'react-bootstrap';
import 'bootstrap';
import Components from './Components';
import Scaling from './Scaling';
import Recalibration from './Recalibration';
import Ecosystem from './Ecosystem';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './style/explained.scss'

function MulticoinExplained() {

    useEffect(() => {
        const onScroll = () => {
          const hiddenElements = document.querySelectorAll('.transition-hide');
          hiddenElements.forEach(el => {
            const Y_OFFSET = (4 * (window.innerHeight/5));
            const elementRect = el.getBoundingClientRect();
            const isVisible = elementRect.top < Y_OFFSET;
    
            if (isVisible) {
              el.classList.add('transition-show');
            } else {
              // Optionally remove the class if it should only show once the condition is met
              // el.classList.remove('transition-show');
            }
          });
        };
    
        // Throttle scroll events to prevent performance issues
        let throttleTimeout = null;
        const throttle = (callback, time) => {
          if (throttleTimeout) return;
    
          throttleTimeout = setTimeout(() => {
            callback();
            throttleTimeout = null;
          }, time);
        };
    
        // Listen for scroll events
        window.addEventListener('scroll', () => throttle(onScroll, 50));
    
        // Cleanup the listener on component unmount
        return () => {
          if (throttleTimeout) clearTimeout(throttleTimeout);
          window.removeEventListener('scroll', onScroll);
        };
      }, []); // Empty array ensures this effect is only run on mount and unmount


    useEffect(() => {
        const onScroll = () => {
            const hiddenElements = document.querySelectorAll('.transition-hide');
            hiddenElements.forEach(el => {
                const Y_OFFSET = (4 * (window.innerHeight/5));
                const elementRect = el.getBoundingClientRect();
                const isVisible = elementRect.top < Y_OFFSET;

                if (isVisible) {
                    el.classList.add('transition-show');
                } else {
                    // Optionally remove the class if it should only show once the condition is met
                    // el.classList.remove('transition-show');
                }
            });
        };

        // Throttle scroll events to prevent performance issues
        let throttleTimeout = null;
        const throttle = (callback, time) => {
            if (throttleTimeout) return;

            throttleTimeout = setTimeout(() => {
                callback();
                throttleTimeout = null;
            }, time);
        };

        // Listen for scroll events
        window.addEventListener('scroll', () => throttle(onScroll, 50));

        // Cleanup the listener on component unmount
        return () => {
            if (throttleTimeout) clearTimeout(throttleTimeout);
            window.removeEventListener('scroll', onScroll);
        };
    }, []); // Empty array ensures this effect is only run on mount and unmount

    let navigate = useNavigate();

    function returnToHome() {
        navigate('/');
    }

    return (
        <div>
            <div className='content container-lg' style={{marginTop : '100px', textAlign : 'left'}}>
                <button className='flex-horizontal return-home-button' onClick={returnToHome} style={{height : '42px', cursor: 'pointer'}}>
                    <img src='/images/arrow_back.png' alt='Back arrow' style={{width : '12px', marginTop : '8px', cursor: 'pointer'}}/>
                    <p className='default_text_color' style={{marginTop : '8px', cursor: 'pointer', zIndex: '100000'}}>
                        Back to Home
                    </p>
                </button>
                <h1 style={{marginTop: '8px', letterSpacing: '-0.05em'}}>Multi Explained</h1>
            </div>
            <div style={{marginTop: '64px', marginBottom: '-32px'}}>
              <a href='/Multi.pdf'>
                <button className='whitepaper_button read-whitepaper-button' title='Read the Whitepaper'>Read the Whitepaper</button>
              </a>
            </div>
            <div className='transition-show'>
                <div className="content container-lg explain-margin " style={{marginTop: '128px', textAlign: 'left'}}>
                    <h4 className='explained-header'>What is Multi?</h4>
                </div>
                <div className="content container-lg flex-evenly" style={{paddingBottom: '0px', paddingTop: '0px'}}>
                    <div className="two-collumn">
                    
                        <p className="default_text_color">
                            Multi is a novel approach to digital currency, aiming to bring stability and decentralization to the world of crypto. Unlike Bitcoin and other cryptocurrencies known for their price volatility, Multi seeks to offer a more reliable store of value by being backed by a diversified basket of tokens, creating a buffer against market fluctuations.
                        </p>

                        <p className="default_text_color">
                            Another key differentiator for Multi is its decentralized nature. Many stablecoins rely on centralized entities and are tied to traditional currencies like the US dollar. Multi aims to break free from these constraints, fostering greater autonomy and resilience within the DeFi ecosystem.
                        </p>

                    </div>

                    <div className="divider"></div>
                    <div className="two-collumn">
                        <p className="default_text_color">
                            Multi utilizes unique algorithms and mechanisms to evolve and function. The market stabilizer optimizes trading on on-chain order book markets, stabilizing market prices and providing users with arbitrage opportunities. The token stream mechanism enables efficient adaptation of the token reserve without relying on time-consuming community consensus.
                        </p>

                        <p className="default_text_color" style={{paddingBottom: '0px', marginBottom: '0px'}}>
                            Looking to the future, Multi envisions a network of interconnected currencies, each backed by unique assets. This network would create a truly decentralized and scalable financial system that mirrors the diversity of the global economy, potentially transforming how we think about and interact with money.
                        </p>
                    </div>

                </div>
            </div>

            <Components/>
            <div className='transition-hide'>
                <Scaling/>
                <div className='explained-divider'/>
            </div>
            <div className='transition-hide'>
            <Recalibration/>
            <div className='explained-divider'/>
            </div>
            <div className='transition-hide'>
                <Ecosystem/>
            </div>
        </div>
    );
}

export default MulticoinExplained;

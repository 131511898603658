import VanillaTilt from 'vanilla-tilt';
import {useEffect, useRef, useState} from 'react'; 
import LinkCard from './LinkCard';


function ForDevelopers(props) { 

    const githubText = 'Check out what we are working on and how we are progressing with our prototype!';
    const docsText = 'Read the docs and learn about how to use and work with Multi!';

    return (

       <div className='content transition-hide' style={{
            // Add some css stuff here maybe?
       }}>
        <h4 className='img-header for-developers-header'>For Developers</h4>
            <div className='for-developers'>
                <LinkCard href='#' title='Github' subtitle='coming soon' text={githubText} isDisabled={true}/>
                <LinkCard href='#' title='Docs'imagePath='/images/code-circle-svgrepo-com.svg' imagePosition='92%' imageScale='73%' subtitle='coming soon' text={docsText} isDisabled={true}/>
            </div>
       </div>
    );

}



export default ForDevelopers;

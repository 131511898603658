import 'react-bootstrap';
import 'bootstrap';
import ExplainedCard from './ExplainedCard';

function Ecosystem() {
    return (
        <div className="content region-start" id='Ecosystem-and-Growth'>
            <div className=''>
                <h4 className='explained-header'>Ecosystem & Growth</h4>
                <div className="flex-evenly recalibration-section reverse-wrap" style={{alignItems: 'flex-start'}}>
                    <div className="flex-vertical recalibration-content" style={{marginBottom:'auto'}}>
                        <p className="default_text_color">
                            Multi aims to create a hierarchical network of interconnected subsidiary Multi variants, each focusing on specific asset types or blockchain ecosystems, forming a diverse and adaptable ecosystem.
                        </p>
                        <ExplainedCard text={"Expands into various asset classes."}/>
                        <ExplainedCard text={"Integrates with multiple blockchain networks."}/>
                        <ExplainedCard text={"Creates specialized subsidiary Multi variants."}/>
                        <ExplainedCard text={"Enables efficient, targeted strategies."}/>
                        <ExplainedCard text={"Lays the foundation for a new type of asset-backed digital currency."}/>

                    </div>
                    <img className='content recalibration-content' alt='Tree structure of nested Multi variants.' src="/images/figures/ecosystem-2.png"/>
                </div>
            </div>

        </div>
    );
}

export default Ecosystem;

import { API } from 'aws-amplify';
import awsconfig from './aws-exports';
API.configure(awsconfig);

export const subscribeToMulticoinNewsletter = async (email, emailSetFunction) => {
    try {
        //emailSetFunction('');

        const response = await API.post('multicoin_aws_services', '/subscribe-to-newsletter', { 
            body: { email : email }
        });
        console.log(response);
        
        return response
    } catch (error) {
        console.error(error);
    }

};
import 'react-bootstrap';
import 'bootstrap';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy(props) {     

    let navigate = useNavigate();

    function returnToHome() {
        navigate('/');
    }

    return (

    <div className='content container-lg' style={{marginTop : '100px', textAlign : 'left'}}>
        <button  className='flex-horizontal return-home-button' onClick={returnToHome} style={{height : '42px', cursor: 'pointer'}}> 
                <img src='/images/arrow_back.png' alt='Back arrow' style={{width : '12px', marginTop : '8px', cursor: 'pointer'}}/>
                <p className='default_text_color' style={{marginTop : '8px', cursor: 'pointer', zIndex: '100000'}}>
                Back to Home
            </p>
        </button>
        <h1 style={{marginTop: '8px'}}>Privacy Policy</h1>
        <p style={{color: '#AAAAAA', marginTop: '16px'}}>
        Last updated: May 27, 2024 </p>
        <p>
Multi respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit the Multi website (the "Website") and our practices for collecting, using, maintaining, protecting, and disclosing that information.
</p>
<h5>Information We Collect and How We Collect It</h5>
<p>We collect several types of information from and about visitors of the Website.</p>

<p><b>We collect non-identifiable information</b> about visitors whenever they interact with the Website (the “Website”). Non-identifiable information may include general geographic location, interactions on the site such as clicking links and other similar information. 
</p>
<p><b>We (the “Website”) use Google Analytics to gather this information.</b> The information is aggregated and used to measure and evaluate interest and visitor demographics. Google operates independently from us and has its own privacy policy and we encourage you to review the Google privacy policy. This website (the “Website”) disables all cookies from Google Analytics except those required for the interaction information gathering.
</p>
<p><b>We collect email addresses from visitors who sign up for our newsletter.</b> By signing up for our newsletter, visitors agree to us storing their email addresses via the third-party service MailChimp. MailChimp operates independently from us and has its own privacy policy. Visitors who wish to remove their email from our system may <a title='contact multicurrency.io' href="mailto:contact@multicurrency.io">contact us</a>.</p>
<h5>How We Use Collected Information</h5>
<p>Multi may collect and use visitor information for the following purposes:
</p>
<p><b>To improve our Website:</b> Information you provide helps us respond to your customer service requests and support needs more efficiently.
</p>
<p><b>To create aggregate data:</b> We may combine identifiable and non-identifiable information to create aggregate data that does not include any personally identifying information. We use this aggregate data to measure interest and audience demographics for our project, and also to provide aggregated statistics to third parties to demonstrate visitor interest and engagement.
</p>
<h5>How We Protect Your Information</h5>
<p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information and data stored on our Website.
</p>

<h5>Sharing Your Personal Information</h5>
<p>We do not sell, trade, or rent information collected from vistors to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors with our business partners and trusted affiliates for the purposes outlined above.
</p>
<h5>Opt-Out of Data Collection</h5>
<p>Visitors can prevent their data from being used and collected by downloading and installing the [Google Analytics Opt-out Browser Add-on]. By doing this none of the visitors' interactions or data will be sent to Google Analytics when using the website (the “Website”).
</p>
<h5>Changes to This Privacy Policy</h5>
<p>Multi has the discretion to update this privacy policy at any time. We encourage visitors to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
</p>
<p>By using this Website, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Website. Your continued use of the Website following the posting of changes to this policy will be deemed your acceptance of those changes.
</p>
<p>For any questions regarding this privacy policy you may <a title='contact multicurrency.io' href="mailto:contact@multicurrency.io">contact us</a>. </p>

<p style={{color : '#AAAAAA'}}>
/ Multi

        </p>
    </div>       
    );
}

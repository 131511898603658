import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { API } from 'aws-amplify';
import awsconfig from './aws-exports';
API.configure(awsconfig);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

window.onscroll = function() {
  /*

  let scrollTrigger = 90;
  let classNameScroll = 'inverted_header';

  // We add pageYOffset for compatibility with IE.
  if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      document.getElementById("header").classList.add(classNameScroll);
      document.getElementById("header").classList.add("white_text");
      document.getElementById("header").classList.remove("key_text");

      //document.getElementById("navbarToggler").classList.add(classNameScroll);
      //document.getElementById("navbarToggler").classList.add("white_text");
      //document.getElementById("navbarToggler").classList.remove("key_text");
  } else {
      //document.getElementById("navbarToggler").classList.remove(classNameScroll);
      //document.getElementById("navbarToggler").classList.remove("white_text");
      //document.getElementById("navbarToggler").classList.add("key_text");

      document.getElementById("header").classList.remove(classNameScroll);
      document.getElementById("header").classList.remove("white_text");
      document.getElementById("header").classList.add("key_text");

      document.getElementById("header-toggler").classList.remove(classNameScroll);
      document.getElementById("header-toggler").classList.remove("white_text");
      document.getElementById("header-toggler").classList.add("key_text");
  }
  */
  };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

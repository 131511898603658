import VanillaTilt from 'vanilla-tilt';
import {useEffect, useRef} from 'react'; 


function TeamMemberCard(props) { 

    const tilt = useRef(null);
    const options = {
        reverse:           false,  // reverse the tilt direction
        max:               2,     // max tilt rotation (degrees)
        perspective:       3000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:             1.01,      // 2 = 200%, 1.5 = 150%, etc..
        speed:             800,    // Speed of the enter/exit transition
        transition:        true,   // Set a transition on enter/exit.
        axis:              null,   // What axis should be disabled. Can be X or Y.
        reset:             true,   // If the tilt effect has to be reset on exit.
        glare:             false,   // if it should have a "glare" effect
        "max-glare":       0.1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false
        };
    
    /* // Temporarily Remove this, the current setup creates issues on Ipad. Removing tilt functionality fixes it.
    useEffect(() => {
        VanillaTilt.init(tilt.current, options);
    
        // Return a cleanup function
        return () => {
            if (tilt.current) {
                tilt.current.vanillaTilt.destroy();
            }
        };
    }, []);

    */

    return (
    <a href={props.href} title={props.name + ' - LinkedIn'}>
        <div className="team-member-card" ref={tilt} style={{perspective: '3000px', transformStyle: 'preserve-3d', transform: 'scale(1)'}}>
            <div className='profile-wrapper flex-center' style={{perspective: '3000px', transformStyle: 'preserve-3d', transform: 'scale(1)'}}>
                <div className="flex-vertical flex-center profile-inner">
                    <img src={props.imagePath} alt={props.name + ' portrait'} className="profile-pic"/>
                    <p className="profile-name">{props.name}</p>
                    <p className="profile-role">{props.role}</p>
                </div>
            </div>
        </div>
    </a>
);
}

// Here we define default values for the props
TeamMemberCard.defaultProps = {
    href: '#',
    imagePath: './images/figures/fredriks.png', 
    name: 'John Doe',
    role: 'Team Member'
};


export default TeamMemberCard;

import './style/new_multicoin_style.scss';
import './style/use-compete-govern-style.scss';
import { useEffect } from 'react';

function UCGCard({title, body, icon, iconScale, marginLeft}) { 
 
    return (
    <div className='ucg-card'>
        <div className='ucg-icon-wrapper'>
            <img src={icon? icon :'images/pulse.svg'} style={{transform: `scale(${iconScale})`, marginLeft: marginLeft}}/>
        </div>

        <h4 className='ucg-card-title'>{title}</h4>    
        <p className='base-text'>{body}</p>

    </div>
);
}

UCGCard.defaultProps = {
    title: 'Dance',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus viverra velit eget velit fermentum porttitor. Sed luctus varius diam id imperdiet.',
    iconScale : 1.0,
    marginLeft: 'auto',
};

export default UCGCard;
import UCGCard from './UCGCard';
import './style/new_multicoin_style.scss';
import './style/use-compete-govern-style.scss';
import { useEffect } from 'react';

function UseCompeteGovern() { 
 
    return (
    <div className="region use-compete-govern" id='Components' style={{paddingTop: '64px', paddingBottom: '0px'}}>
        <div className="content transition-hide" style={{height: 'fit-content', minHeight: '500px'}}>

            <h4 style={{marginTop: 'auto', marginBottom : 'auto', fontSize: 'min(52px, 11vw)'}}>Use<span style={{color: '#586ce1'}}>,</span> Compete <span style={{color: '#586ce1'}}>&</span> Govern<span style={{color: '#586ce1'}}>:</span></h4>
            <h5>Empowering Decentralized, Asset-Backed Transactions.</h5>

            <div className='ucg-card-wrapper'>
                
                <UCGCard                       
                    title='Use' 
                    body="Trade tokens efficiently with Multi's Market Stabilizer. This mechanism enhances liquidity and reduces slippage by leveraging Multi's diverse token reserve."
                    icon="images/hands.svg"
                    iconScale="0.8"
                />     
                
                <UCGCard
                    title='Compete' 
                    body="Participate in a long-term performance game. Select tokens you believe will outperform others over time and commit to regular deposits. Earn rewards based on how well your chosen tokens perform."
                    icon="images/compete.svg"
                    iconScale="0.6"
                />   
                
                <UCGCard                       
                    title='Govern' 
                    body="Shape Multi's future through its DAO. Use governance tokens to vote on key policies like inflation rates and approve new tokens for Multi's reserves."
                    icon="images/govern.svg"
                    iconScale="0.65"
                    marginLeft='14px'
                />   

            </div>
          
        </div>
    </div>
);
}

export default UseCompeteGovern;
import 'react-bootstrap';
import 'bootstrap';
import Newsletter from './Newsletter';
import { useNavigate } from 'react-router-dom';


function Footer(props) { 
    
    let navigate = useNavigate();

    function showPrivacyPolicy() {
        navigate("/privacy-policy");
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
    
    <footer className="footer">
        
        <div className="content flex-vertical" style={{display: 'flex', paddingLeft: 'min(6vw, 80px)', paddingBottom: '0px'}}>
            
            <Newsletter/>             

            <div style={{display: 'flex', justifyContent: 'left', marginTop: '8px'}}>
                <a className='icon-link' href="https://discord.gg/CfnCQ99NJ7" title='Join the Discord'>
                    <img className='icon-link' src='/images/discord_logo.png' alt='Discord logo'/>
                </a>
                <a className='icon-link' href="https://x.com/MultiCurrencyIO" title='Follow on X (formerly known as Twitter)' style={{marginLeft: '8px'}}>
                    <img className='icon-link' src='/images/twitter_logo.png' alt='Twitter logo'/>
                </a>
                <a className='icon-link' href="https://t.me/MultiCurrencyIO" title='Join the Telegram' style={{marginLeft: '8px'}}>
                    <img className='icon-link' src='/images/telegram_logo.png' alt='Telegram logo'/>
                </a>
                <a className='icon-link' href="mailto:contact@multicurrency.io" title='Send an Email' style={{marginLeft: '8px'}}>
                    <img className='icon-link' src='/images/email_icon.png' alt='Email logo'/>
                </a>
                
            </div>

            <div className='footer-link' onClick={showPrivacyPolicy} >Privacy Policy</div>

            <p style={{ color: '#8fb2ff', marginTop: '4px', marginBottom: '60px'}}>© 2024 Multi</p>

        </div>
        
    </footer>

);
}

export default Footer;


import React, { useState } from 'react';
import './style/new_multicoin_style.scss';
import './style/faq-style.scss';
import arrowIcon from './images/arrow_back.png'; // Ensure this path is correct

function FAQ({ Q, A, first}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFAQ = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={'faq-item' + (first? ' first-faq' : '')} onClick={toggleFAQ}>
            <div className='faq-question'>
                <h4 className='faq-question'>{Q}</h4>
                <img
                    src={arrowIcon}
                    className={`faq-arrow ${isOpen ? 'open' : ''}`}
                    alt='arrow icon'
                />
            </div>
            {isOpen && <p className='faq-answer'>{A}</p>}
        </div>
    );
}

FAQ.defaultProps = {
    Q: 'Why is Fredrik so cool?',
    A: 'Because that is just how it is.',
    first: false
};

export default FAQ;

import VanillaTilt from 'vanilla-tilt';
import {useEffect, useRef, useState} from 'react'; 


function LinkCard(props) { 

    const [isDisabled, setIsDisabled] = useState(props.isDisabled);

    
    const tilt = useRef(null);
    const options = {
        reverse:           false,  // reverse the tilt direction
        max:               4,     // max tilt rotation (degrees)
        perspective:       1000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:             1.01,      // 2 = 200%, 1.5 = 150%, etc..
        speed:             800,    // Speed of the enter/exit transition
        transition:        true,   // Set a transition on enter/exit.
        axis:              null,   // What axis should be disabled. Can be X or Y.
        reset:             true,   // If the tilt effect has to be reset on exit.
        glare:             false,   // if it should have a "glare" effect
        "max-glare":       0.1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false
        };
    
    /* // Temporarily Remove this, the current setup creates issues on Ipad. Removing tilt functionality fixes it.
    useEffect(() => {
        VanillaTilt.init(tilt.current, options);
    
        // Return a cleanup function
        return () => {
            if (tilt.current) {
                tilt.current.vanillaTilt.destroy();
            }
        };
    }, []);
    */

    return (
        <a style={props.style} href={isDisabled ? undefined : props.href}>
            <div ref={tilt} className='link-card' style={props.style}>
                
                <div className='link-card-color-strip-wrapper'>
                    <div className='link-card-color-strip'/>
                </div>
          
                <div className='link-card-content' style={{backgroundImage: `url(${props.imagePath})`, backgroundPosition: (props.imagePosition + ' 50%'), backgroundSize: (props.imageScale + ' auto'), backgroundRepeat: 'no-repeat'}}>
                    <div className='link-card-header-wrapper'>
                        <h3 className='link-card-header'>
                            {props.title}
                        </h3>
                        <p>
                            {props.subtitle}
                        </p>
                    </div>
                    
                    <p>
                        {props.text}
                    </p>
                </div> 
            </div>
        </a>
    );

}

// Here we define default values for the props
LinkCard.defaultProps = {
    href : '#',
    imagePath : '/images/github_background.png', 
    title : 'John Doe',
    subtitle : '',
    text : 'Team Member',
    isDisabled : false,
    imageScale : '60%',
    imagePosition : '80%'
};

export default LinkCard;

import 'react-bootstrap';
import 'bootstrap';
import VanillaTilt from 'vanilla-tilt';
import {useEffect, useRef} from 'react'; 

function Discord() { 

    const tilt = useRef(null);
    const options = {
        reverse:           false,  // reverse the tilt direction
        max:               2,     // max tilt rotation (degrees)
        perspective:       3000,   // Transform perspective, the lower the more extreme the tilt gets.
        scale:             1.002,      // 2 = 200%, 1.5 = 150%, etc..
        speed:             800,    // Speed of the enter/exit transition
        transition:        true,   // Set a transition on enter/exit.
        axis:              null,   // What axis should be disabled. Can be X or Y.
        reset:             true,   // If the tilt effect has to be reset on exit.
        glare:             false,   // if it should have a "glare" effect
        "max-glare":       0.1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false
        };
      
    useEffect(() => {
        VanillaTilt.init(tilt.current, options);
    
        // Return a cleanup function
        return () => {
            if (tilt.current) {
                tilt.current.vanillaTilt.destroy();
            }
        };
    }, []);

    return (
    <div className="region-start" id='Components'>
        <div className='transition-hide'>
            <div ref={tilt} className="content discord-background flex-vertical" style={{justifyContent: 'center', paddingTop: '32px', paddingBottom: '46px', perspective: '3000px', transformStyle: 'preserve-3d', borderRadius: '12px'}}>
        
                    <h4 className='discord-header hoverInFront' style={{transform: 'translateZ(32px)'}}>JOIN THE CONVERSATION OVER ON</h4>
                    <img style={{maxWidth: '500px', margin: 'auto',   transform: 'translateZ(32px)'}}src='images/discord-logo-white.svg'/>
                    <a href='https://discord.gg/CfnCQ99NJ7'>
                        <button className='white-button hoverInFront' title='Join the Discord' style={{transform: 'translateZ(32px)'}}>Join the Discord</button>
                    </a>
                
                <div class="discord-speach-pin" style={{position: 'absolute', right: '15%', bottom: '-40px'}}/>
            </div>
        </div>
    </div>
    );
}

export default Discord;


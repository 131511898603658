import React, { useState, useEffect, useRef } from 'react';

const IMAGE_URLS = [
  'images/parallax_5.png',
  'images/parallax_4.png',
  'images/parallax_3.png',
  'images/parallax_2.png',
  'images/parallax_1.png',
];

const Parallax = (opacity) => {
  const [offsets, setOffsets] = useState(IMAGE_URLS.map(() => ({ x: 0, y: 0 })));
  const mousePosition = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const animationFrameId = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      mousePosition.current = { x: e.clientX, y: e.clientY };
    };

    window.addEventListener('mousemove', handleMouseMove);

    let start;
    const step = (timestamp) => {
      if (start === undefined) start = timestamp;
      const elapsed = timestamp - start;
      const depth = [0.04, 0.05, 0.06, 0.08, 0.18];
      const mouseEffect = 0.3;

      const newOffsets = depth.map((depthValue, index) => {
        const xOffset = (mousePosition.current.x - window.innerWidth / 2) * mouseEffect * depthValue - (window.innerWidth / 2);
        const yOffset = (((mousePosition.current.y - window.innerHeight / 2)) * mouseEffect * depthValue) - (window.scrollY * depthValue * 8);
        const sineOffset = Math.sin(elapsed * 0.001 + index) * 70 * Math.sqrt(depthValue);
        const aspectOffset = ((window.innerHeight / window.innerWidth) / (1080 / 1920))
        const imageHeight = (1080/1920) * window.innerWidth;
        
        let vertical_offset =  1 - Math.sqrt(aspectOffset) * (window.innerHeight * 0.38) - imageHeight;
        vertical_offset = (window.innerHeight - imageHeight)/ 2.6


        return { x: xOffset, y: yOffset + sineOffset + vertical_offset };
      });

      setOffsets(newOffsets);
      animationFrameId.current = window.requestAnimationFrame(step);
    };

    animationFrameId.current = window.requestAnimationFrame(step);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (animationFrameId.current) {
        window.cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []); // Ensures this effect runs only once on mount



  return (
    <div style={{ position: 'absolute', width: '100%', height: '100vh', overflow: 'hidden', left: '0', top: '0', opacity: opacity  }}>
      {IMAGE_URLS.map((url, index) => (
        index !== 2 ? (
          <img
            key={index}
            src={url}
            alt={`Layer ${index}`}
            style={{
              opacity: opacity,
              position: 'absolute',
              width: '100%',
              height: 'auto',
              transform: `translate(${offsets[index].x}px, ${offsets[index].y}px)`,
              pointerEvents: 'none',
              zIndex: 900002,
 
            }}
          />
        ) : (
          <div
            key={index}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className='animated-fadeInUpwards'
          >
            {/* <MainContainer/>   */}
          </div>
        )
      ))}
    </div>
  );
};

export default Parallax;

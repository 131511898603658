import React from 'react';

function MySalesContractsOverview(props) {
  // Destructuring props for easier access
  const {
    activeSalesContracts,
    totalFundRewards,
    averagePerformance,
    fundRewardsAvailable,
    govRewardsAvailable,
    totalGovRewardsValue,
    currencySymbol,
    onWithdrawRewardsClick,
    onDepositAllSalesContractsClick
  } = props;

  // Helper function to determine if buttons should be disabled
  const isWithdrawDisabled = govRewardsAvailable <= 0;
  const isDepositDisabled = activeSalesContracts <= 0;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', background: '#f8f8f8' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '20px' }}>
          <div>Your Current Active Token Streams</div>
          <div>{activeSalesContracts}</div>
        </div>
        <div style={{ marginRight: '20px' }}>
          <div>Total Fund Rewards</div>
          <div>{currencySymbol}{totalFundRewards.toLocaleString()}</div>
        </div>
        <div style={{ marginRight: '20px' }}>
          <div>Average Performance</div>
          <div>{averagePerformance}%</div>
        </div>
        <div style={{ marginRight: '20px' }}>
          <div>Currently Available Fund rewards</div>
          <div>{currencySymbol}{fundRewardsAvailable.toLocaleString()}</div>
        </div>
        <div>
          <div>Currently Available GOV rewards</div>
          <div>{currencySymbol}{govRewardsAvailable.toLocaleString()} ({totalGovRewardsValue.toLocaleString()})</div>
        </div>
      </div>
      <div>
        <button
          style={{ marginRight: '10px', backgroundColor: isWithdrawDisabled ? '#ccc' : '#000', color: '#fff', padding: '10px 20px', border: 'none', cursor: isWithdrawDisabled ? 'default' : 'pointer' }}
          onClick={onWithdrawRewardsClick}
          disabled={isWithdrawDisabled}
        >
          Withdraw All Rewards
        </button>
        <button
          style={{ backgroundColor: isDepositDisabled ? '#ccc' : '#000', color: '#fff', padding: '10px 20px', border: 'none', cursor: isDepositDisabled ? 'default' : 'pointer' }}
          onClick={onDepositAllSalesContractsClick}
          disabled={isDepositDisabled}
        >
          Deposit all ({activeSalesContracts}) Token Streams
        </button>
      </div>
    </div>
  );
}

MySalesContractsOverview.defaultProps = {
    activeSalesContracts: 0,
    totalFundRewards: 0.00,
    averagePerformance: 0.0,
    fundRewardsAvailable: 0.00,
    govRewardsAvailable: 0.00,
    totalGovRewardsValue: 0.00,
    currencySymbol: '$',
    onWithdrawRewardsClick: () => {},
    onDepositAllSalesContractsClick: () => {}
  };

export default MySalesContractsOverview;
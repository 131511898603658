import 'react-bootstrap';
import 'bootstrap';
import ExplainedCard from './ExplainedCard';

function Scaling() {
    return (
        <div>
        <div className="region-start content flex-vertical story-box explained-container" id='Multicoin-Scaling'>
            <div className=''>
                <h4 className='explained-header'>Market Stabilizer</h4>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div>
                        <p>
                            The market stabilizer automatically optimizes trading in the Multi ecosystem. When users place orders, it finds the best prices by trading multiple reserve tokens simultaneously. This process ensures users get optimal rates while adjusting Multi's supply to match demand. As a result, users benefit from enhanced liquidity, more efficient trading, and increased market price stability.
                    </p>
                        <img className='explained-image-reverse' style={{marginBottom: '46px'}} src='images/cropped_scale.png'/>
                        <ExplainedCard text={"Automatically scales Multi's supply to market demand."}/>
                        <ExplainedCard text={"Engineered to mitigate systemic risks, including bank runs."}/>
                        <ExplainedCard text={"Mitigates the risk of market manipulation and price swings."}/>
                        <ExplainedCard text={"Enhances market liquidity as a neutral participant."}/>
                        <ExplainedCard text={"Generates a reliable price feed, improving market price accuracy."}/>
                        <ExplainedCard text={"Enables swapping fund tokens for any underlying token via multilateral trades."}/>

                    </div>
                    <img className='explained-image' style={{width : '50%', paddingLeft: '16px', maxHeight: '100%', minHeight: '100%', marginBottom: '46px', objectFit: 'contain'}} src='images/cropped_scale.png'/>
                </div>
            </div>
        </div>
        <div className="flex-left flex-horizontal multicoin-scaling-container explained-white-border" style={{flexWrap: 'nowrap', alignItems: 'flex-start', marginTop: '32px'}}>
            <div className="flex-vertical scale-image-wrapper">
                    <p className="default_text_color scaling-text">
                        The market stabilizer ensures that each coin is properly backed by precise quantities of tokens.
                    </p>
                <img className='scaling-image' alt='Set of starting Backing tokens' src="/images/figures/scale-start.png" />
            </div>

            <div className="vertical-line"></div>

            <div className="flex-vertical scale-image-wrapper">
                <p className="default_text_color scaling-text">
                    As Multi grows, the system purchases the appropriate quantities to maintain constant token ratios.
                </p>
                <img className='scaling-image' alt='Backing tokens grow proportionally' src="/images/figures/scale-grow.png"/>
            </div>

            <div className="vertical-line"></div>

            <div className="flex-vertical scale-image-wrapper">
                <p className="default_text_color scaling-text">
                    Multi utilizes a multilateral exchange across different markets to ensure precise backing.
                </p>
                <img className='scaling-image' alt='Backing tokens after proportional growth' src="/images/figures/scale-final.png"/>
            </div>
        </div>
    </div>
    );
}


export default Scaling;

import FAQ from './FAQ';
import './style/new_multicoin_style.scss';
import './style/faq-style.scss';

function FAQPage() { 

    const questions = [
        {
            Q: "What is Multi?",
            A: "Multi is a new type of digital currency that aims to be more stable and valuable than traditional cryptocurrencies. It's backed by a diverse collection of other digital tokens, which helps it maintain its value.",
        },
        {
            Q: "How is Multi different from Bitcoin or other cryptocurrencies?",
            A: "Unlike Bitcoin or most other cryptocurrencies, Multi is backed by a reserve of various digital tokens. This means its value is tied to real assets, potentially making it more stable and useful as a currency.",
        },
        {
            Q: "What is the Market Stabilizer?",
            A: "The Market Stabilizer is an automated market maker embedded in order books that helps maintain price stability by executing trades at favorable exchange rates or not at all.",
        },
        {
            Q: "What is Token Streams?",
            A: "Token Streams are a system in Multi that gradually collects and adds different tokens to its reserve. Participants contribute tokens over time, and the system rewards them based on how well those tokens perform in the long run.",
        },
        {
            Q: "Can Multi be used as a decentralized exchange (DEX)?",
            A: "Yes, Multi's design allows it to function as a DEX, offering efficient trading, enhanced liquidity, robust price discovery, and potential for earning trading fees.",
        },
        {
            Q: "How do I get Multi?",
            A: "Multi isn't released yet, but once it is, you can obtain it by participating in the system as a reserve provider (providing other tokens to the reserve) or by purchasing it on exchanges.",
        },
        {
            Q: "Can I exchange Multi back into other cryptocurrencies?",
            A: "Yes, Multi is designed to be easily exchangeable with other cryptocurrencies. The Market Stabilizer component aims to make these exchanges efficient and cost-effective.",
        },
        {
            Q: "Is Multi safe to use?",
            A: "Multi is designed with multiple safety mechanisms, including gradual token purchases and performance-based rewards. However, like all cryptocurrencies, it comes with risks, and you should always do your own research.",
        },
        {
            Q: "What happens if the value of the tokens backing Multi goes down?",
            A: "Multi is designed to be resilient to individual token price fluctuations due to its diverse backing. If some tokens decrease in value, others may increase, helping to maintain overall stability.",
        },
        {
            Q: "Does Multi have its own blockchain?",
            A: "No, Multi is a token that runs on top of one or more public blockchains.",
        },
        {
            Q: "How is the value of Multi determined?",
            A: "Multi's value is determined by the combined value of the tokens in its reserve, as well as market supply and demand. The Market Stabilizer helps to maintain price stability by adjusting the supply of Multi.",
        },
           {
            Q: "Can I use Multi to buy things?",
            A: "While Multi is designed to be used as a currency, its actual use for purchases will depend on its adoption by merchants and platforms. As the system develops, the goal is for Multi to be widely accepted for various transactions.",
        }
        ];

    return (
    <div className="region use-compete-govern" id='Components' style={{paddingTop: '200px', paddingBottom: '0px'}}>
        <div className="content" style={{height: 'fit-content', minHeight: '1000px', display: 'flex', flexDirection: 'column', alignContent: 'left' }}>
            <div style={{width: 'fit-content'}}>
                <h3 className='faq-header'><span style={{color: '#586ce1'}}>F</span>requently</h3>
                <h3 className='faq-header'><span style={{color: '#586ce1'}}>A</span>sked</h3>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <h3 className='faq-header'><span style={{color: '#586ce1'}}>Q</span>uestions</h3>
                    <div style={{width: '64px', height: '28px', display: 'flex'}}>
                        <h3 className='faq-header' style={{color: '#586ce1', transform: 'scale(0.8) rotate(-2deg)', marginTop: '-6px', marginLeft: '0px'}}>?</h3>
                        <h3 className='faq-header' style={{color: '#586ce1', transform: 'scale(1.2) rotate(14deg)', marginTop: '-17px', marginLeft: '-4px'}}>?</h3>
                    </div>
                </div>
            </div>
            <p className='faq-explaination'>Welcome to the Multi FAQ section! Here, we've compiled answers to the most commonly asked questions to help you understand Multi.</p>
            <div className='faq-array'>

                {questions.map((element, index) => {
                    return <FAQ Q={element.Q} A={element.A} first={index==0}/>
                })}
            </div>
            <div className='faq-something-missing'>
                <img src='images/question-circle.svg'/>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '16px'}}>
                    <h3>Do You have More Questionsor is Something Missing?</h3>
                    <p>Try reading the whitepaper or contact us directly at contact@multicurrency.io</p>
                </div>
            </div>
        </div>
    </div>
);
}

export default FAQPage;
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

function FundDistributionPie({ fundData, hoveredTime }) {
  const pieChartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [selectedTimestampData, setSelectedTimestampData] = useState(null);
  const [prevTimestampData, setPrevTimestampData] = useState(null);

  useEffect(() => {
    // Find the data corresponding to the hovered time
    const selectedData = fundData.find((data) => data.date === hoveredTime);
    setSelectedTimestampData(selectedData);

    // Find the data corresponding to the previous time (if available)
    const prevDataIndex = fundData.findIndex((data) => data.date === hoveredTime) - 1;
    if (prevDataIndex >= 0) {
      const prevData = fundData[prevDataIndex];
      setPrevTimestampData(prevData);
    } else {
      setPrevTimestampData(null);
    }
  }, [fundData, hoveredTime]);

  useEffect(() => {
    if (selectedTimestampData) {
      const assetLabels = Object.keys(selectedTimestampData).filter((key) => key !== 'date');
      const assetValues = assetLabels.map((key) => selectedTimestampData[key]);

      if (!chartInstance) {
        // Create the pie chart instance if it doesn't exist
        const ctx = pieChartRef.current.getContext('2d');
        const newChartInstance = new Chart(ctx, {
          type: 'pie',
          data: {
            labels: assetLabels,
            datasets: [
              {
                data: assetValues,
                backgroundColor: [
                  'red',
                  'blue',
                  'green',
                  'yellow',
                  'orange',
                  'purple',
                  'pink',
                  'cyan',
                  'magenta',
                  'lightblue',
                ],
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display: true,
                position: 'right',
              },
              datalabels: {
                formatter: (value, context) => {
                  const label = context.chart.data.labels[context.dataIndex];
                  const total = assetValues.reduce((sum, val) => sum + val, 0);
                  const percentage = ((value / total) * 100).toFixed(2);
                  let changeText = '';

                  if (prevTimestampData && prevTimestampData[label] !== undefined) {
                    const prevValue = prevTimestampData[label];
                    const change = (((value - prevValue) / prevValue) * 100).toFixed(2);

                    changeText = ` (${change > 0 ? '+' : ''}${change}%)`;
                  }

                  return `${label}: ${value} (${percentage}%)${changeText}`;
                },
              },
            },
          },
        });
        setChartInstance(newChartInstance);
      } else {
        // Update pie chart data
        chartInstance.data.labels = assetLabels;
        chartInstance.data.datasets[0].data = assetValues;

        // Re-render the pie chart
        chartInstance.update();
      }
    }
  }, [selectedTimestampData, chartInstance, prevTimestampData]);

  return (
    <div className='fund-distribution-pie'>
      {selectedTimestampData && (
        <div className='pie-data'>
          <h3>Data for {hoveredTime}</h3>
          <ul>
            {selectedTimestampData &&
              Object.keys(selectedTimestampData)
                .filter((key) => key !== 'date')
                .map((key) => {
                  const value = selectedTimestampData[key];
                  const prevValue = prevTimestampData && prevTimestampData[key];
                  const changePercentage =
                    prevValue !== undefined
                      ? (((value - prevValue) / prevValue) * 100).toFixed(2)
                      : 'N/A';
                  const textColor = prevValue !== undefined && value > prevValue ? 'green' : 'red';
  
                  return (
                    <li key={key}>
                      <span className='asset-label'>{key}:</span>
                      <span className='asset-value' style={{ color: textColor }}>
                        {value} ({changePercentage}%)
                      </span>
                    </li>
                  );
                })}
          </ul>
        </div>
      )}
      <canvas ref={pieChartRef} />
    </div>
  );
}

FundDistributionPie.defaultProps = {
    fundData: [
        { date: '2023-01-01', asset1: 100, asset2: 200 },
        { date: '2023-02-01', asset1: 150, asset2: 180 },
        { date: '2023-03-01', asset1: 130, asset2: 210 },
        { date: '2023-04-01', asset1: 200, asset2: 190 },
        { date: '2023-05-01', asset1: 160, asset2: 220 },
        { date: '2023-06-01', asset1: 170, asset2: 230 },
        { date: '2023-07-01', asset1: 180, asset2: 240 },
        { date: '2023-08-01', asset1: 190, asset2: 250 },
        { date: '2023-09-01', asset1: 200, asset2: 260 },
        { date: '2023-10-01', asset1: 210, asset2: 220 },
        { date: '2023-11-01', asset1: 205, asset2: 215 },
        { date: '2023-12-01', asset1: 220, asset2: 210 },
        { date: '2024-01-01', asset1: 230, asset2: 220 },
        { date: '2024-02-01', asset1: 240, asset2: 230 },
        { date: '2024-03-01', asset1: 250, asset2: 240 },
        { date: '2024-04-01', asset1: 260, asset2: 250 },
        { date: '2024-05-01', asset1: 270, asset2: 260 },
        { date: '2024-06-01', asset1: 280, asset2: 270 },
        { date: '2024-07-01', asset1: 290, asset2: 280 },
        { date: '2024-08-01', asset1: 300, asset2: 290 },
        { date: '2024-09-01', asset1: 310, asset2: 300 },
        { date: '2024-10-01', asset1: 320, asset2: 310 },
        { date: '2024-11-01', asset1: 330, asset2: 320 },
        { date: '2024-12-01', asset1: 340, asset2: 330 },
    ],
  };

export default FundDistributionPie;

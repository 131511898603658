import React from 'react';

function DraftBidsBanner({ bidsInDraft, totalBidValue, totalGovStake, currentBidRatio }) {
  return (
    <div className="draft-bids-banner">
      <span className="banner-info">{`Bids in Draft: ${bidsInDraft}`}</span>
      <span className="banner-info">{`Total Bid Value: ${totalBidValue}`}</span>
      <span className="banner-info">{`Total GOV Stake: ${totalGovStake}`}</span>
      <span className="banner-info">{`Current Bid Ratio: ${currentBidRatio}`}</span>
      <button className="create-bid-button">Create Bid</button>
    </div>
  );
}

DraftBidsBanner.defaultProps = {
    bidsInDraft: 12,
    totalBidValue: '1,000,000',
    totalGovStake: '500,000',
    currentBidRatio: '1.5:1'
  };

export default DraftBidsBanner;
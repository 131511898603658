import 'react-bootstrap';
import 'bootstrap';
import './style/new_multicoin_style.scss';
import TogglerButton from './TogglerButton';
import React, { useState, useEffect, useRef } from 'react'; 
import { useNavigate } from 'react-router-dom';
import baseImage from './images/token_stack.png';
import EasyExplainationSubPoint from './EasyExplainationSubPoint';
import WaveRegion from './WaveRegion';


function EasyExplaination() { 
    const [storyMode, setStoryMode] = useState(false);
    // State to force re-mounting the header
    const [key, setKey] = useState(0);
    const [componentHeight, setComponentHeight] = useState('770px');
    const [transitionClass, setTransitionClass] = useState('transition-hide');
    const parentRef = useRef(null);

    // Effect to update the key when storyMode changes
    useEffect(() => {
        setKey(prevKey => prevKey + 1);
    }, [storyMode]);
    
    let navigate = useNavigate();

    function goToProtocolExplaination() {
        window.scrollTo(0, 0);
        navigate('/explained');
       
    }

    const modeImages = {
        baseMode: baseImage, // Update with your actual image path
    };

    const scrollingStyle = {
        position: 'absolute',
        bottom: '-4px',
    }

    const updateSize = () => {
        if (parentRef.current) {
            const parentHeight = parentRef.current.offsetHeight;
            setComponentHeight(`${parentHeight + 160}px`);
        }
    }

    useEffect(() => {
        updateSize();
    }, [storyMode]);


    useEffect(() => {
        window.addEventListener('resize', updateSize);

        // Call updateSize to set initial height
        updateSize();

        // Clean up listener when component unmounts
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <WaveRegion>
            <div className='container-lg easy-explaination-background' style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0, // Ensuring it fills the container horizontally for positioning
                bottom: 0, // Ensuring it fills the container vertically for positioning
                backgroundImage: `url(${modeImages.baseMode})`,
                backgroundSize: '32% auto', // Adjust this value as needed, e.g., 80% of the container width
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '90% center', // Shifts the background image to the right and vertically centers it
                opacity: storyMode ? 0 : 1,
                transition: 'opacity 0.5s ease-in-out',
            }}></div>

            <div className='container-lg easy-explaination-background' style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0, // Ensuring it fills the container horizontally for positioning
                bottom: 0, // Ensuring it fills the container vertically for positioning
                backgroundImage: `url(${modeImages.storyMode})`,
                backgroundSize: '32% auto', // Again, adjust this value as needed
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '95% center', // This ensures the background image is right-aligned
            opacity: storyMode ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
        }}></div>

        <div className='story-box' ref={parentRef}>
            <div className="content flex-vertical" style={{}}>
                <div style={{display: 'flex', justifyContent:'left', flexDirection : 'column', textAlign : 'left'}}>
                    <h2 className='story-header-small fadeIn no-width-transition '>A New Era of</h2>
                    <h2 className='story-header fadeIn no-width-transition '>Decentralized Currency</h2>
                    <div className='line-sep'></div>
                </div>
                <div className='story-container justify-left no-width-transition '>
                    
                    <div style={{zIndex: '1000'}}>
                        <EasyExplainationSubPoint 
                            title='Dynamically Backed' 
                            body='Backed by a diverse and growing asset pool for true decentralized price stability.'/>
                        <EasyExplainationSubPoint
                            title='Emergent Strategy' 
                            body="The aggregated actions of the community directly determine Multi's composition without requiring consensus."
                            icon="images/strategy.svg"
                            iconScale="0.8"
                            marginLeft='11px'/>
                        <EasyExplainationSubPoint
                            title='Self-Adjusting Supply' 
                            body="Multi's supply automatically adapts to market demand, improving liquidity and stability."
                            icon="images/balance-scale.svg"
                            iconScale="0.8"/>
                    </div>
                    
                    <div className='no-width-transition ' key={key}>


                        <div className='fadeIn read-more-button' onClick={goToProtocolExplaination} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'fit-content', marginTop: '8px'}}>
                            <p style={{marginBottom: '0px'}} className='read-more'>Read More</p>
                            <img style={{width: '22px', marginTop: '2px'}} src='images/arrow-sm-right.svg'/>
                        </div>
                
                    </div>
                  
                </div>
            </div>
        </div>
        </WaveRegion>
);
}

export default EasyExplaination;
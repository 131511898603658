import 'react-bootstrap';
import 'bootstrap';
import WaveHeader from './WaveHeader';
import GlobeBackground from './GlobeBackground';
import { useEffect, useState } from 'react';
import Parallax from './Parallax';


function HeroLanding() { 
    
    const [opacity, setOpacity] = useState(1.0);

    const handleScroll = () => {
        const wHeight = window.innerHeight/4.6;
        let scroll = Math.min(window.scrollY, 400);
        setOpacity(1 - (Math.max(scroll - wHeight, 0) / wHeight));
    };

    //Scroll Handling
    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Remove scroll event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>

            <div className="content container-lg flex-justify flex-vertical hero-landing" style={{ paddingBottom: '0px'}}  id ='Start'>
                <div style={{opacity : Math.sqrt(opacity)}}>
                    <Parallax opacity={opacity}/>
                </div>
                <div style={{opacity: opacity, position: 'relative', top: 'max(34vh, 200px)', zIndex: '4000000'}}>
                    <WaveHeader center='true' text='Multi'/>
                    {/*  <h1 style={{marginLeft: 'auto' ,marginRight: 'auto'}}>Multi</h1> */}
                    <h2 style={{marginLeft: 'auto' ,marginRight: 'auto', filter: 'drop-shadow(0px 0px 12px rgba(252,252,252,255)', textShadow: '-1px 1px 52px rgba(252,252,252,1)'}}>Backed by a Diversified Token Reserve</h2>
                </div>
                
                <GlobeBackground/>
            </div>
        </div>
    );
}

export default HeroLanding;

import 'react-bootstrap';
import 'bootstrap';
import './style/new_multicoin_style.scss';
import React from 'react'; 

function ReadTheWhitepaper() { 

    return (
    <div className="region transition-hide" style={{paddingTop: '64px'}}>
        <div className='content flex-horizontal'>
            <div className="flex-vertical" style={{ justifyContent: 'left', textAlign: 'left', alignContent: 'center'}}>
                <div className='read-white-paper-region'>
                    <h4 className='img-header purple-text'>Want to Dive Deeper?</h4>
                    <p className='base-text'>
                        Built on innovative mechanism design principles, our whitepaper presents a robust framework for creating decentralized currency. Read the whitepaper to understand how Multi operates and its potential to revolutionize decentralized finance (DeFi).
                    </p>
                    <a href='/Multi.pdf'>
                        <button className='whitepaper_button read-whitepaper-button' title='Read the Whitepaper'>Read the Whitepaper</button>
                    </a>
                </div>
            </div>
            <img src='/images/folder.png' className='whitepaper-image'/>
        </div>
    </div>
);
}

export default ReadTheWhitepaper;
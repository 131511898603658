import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Dashboard from './Dashboard/Dashboard';
import './style/multicoin_style.scss';
import NavBar from './NavBar';
import './style/bootstrap-override.scss'
import Footer from './Footer';
import { API } from 'aws-amplify';
import awsconfig from './aws-exports';
import Home from './Home.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import MulticoinExplained from './MulticoinExplained.js';
import FAQPage from './FAQPage.js';


API.configure(awsconfig);


function App() {

  return (
    <div className="App">

        <Router>

          <Routes>
            <Route path="/" element={<div><NavBar/><Home/><Footer/></div> }/>
            <Route path="/privacy-policy" element={<div><NavBar/><PrivacyPolicy/><Footer/></div>}/>
            <Route path="/explained" element={<div><NavBar/><MulticoinExplained/><Footer/></div>}/>
            <Route path="/faq" element={<div><NavBar/><FAQPage/><Footer/></div>}/>
            {/* <Route path="/dashboard/*" element={<Dashboard/>}/>
             Redirect all other routes to root */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          
        </Router>
        
  </div>


  );
}

export default App;

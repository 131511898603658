const awsconfig = {
    "aws_project_region": "eu-north-1",
    "aws_cloud_logic_custom": [
        {
            "name": "multicoin_aws_services",
            "endpoint": "https://6eei1kvp6e.execute-api.eu-north-1.amazonaws.com/Default",
            "region": "eu-north-1"
        }
    ]
};

export default awsconfig;
import 'react-bootstrap';
import 'bootstrap';
import './style/explaination-sub-point-style.scss';


function EasyExplainationSubPoint({title, body, icon, iconScale, marginLeft}) { 
    

    return (
        <div className='sub-point-wrapper'>
            <div className='icon-wrapper'>
                <img src={icon? icon :'images/pulse.svg'} style={{transform: `scale(${iconScale})`, marginLeft: marginLeft}}/>
            </div>
            <p><span className='title'>{title}:</span> {body}</p>
        </div>
);
}

EasyExplainationSubPoint.defaultProps = {
    title: 'New Mechanism',
    body: 'Utilize a cool thing to do amazing stuff.',
    iconScale : 1.0,
    marginLeft: 'auto',
};


export default EasyExplainationSubPoint;
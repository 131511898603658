import 'react-bootstrap';
import 'bootstrap';
import WaveRegion from './WaveRegion';

function Components() {
    return (
        <WaveRegion style={{marginTop: '128px', paddingTop: '0px'}}>
            <div className="region-start transition-hide" id='Components'>
                <div className="content flex-vertical explained-container">
                    <h4 className="explained-header white-text">Components</h4>
                    <p className="default_text_color white-text">
                        The market stabilizer generates a price feed that is used when determining the value of tokens flowing in via streams. These contributions change the composition of Multi's reserves, which in turn influences how the market stabilizer operates, as it adjusts to the new token ratios.
                    </p>

                    <img className='components-image show-when-big' alt='Multi System Components' src="/images/figures/components-horizontal.png"/>
                    <img className='components-image show-when-small' alt='Multi System Components' src="/images/figures/components.png"/>
                </div>
            </div>
        </WaveRegion>
    );
}

export default Components;

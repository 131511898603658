import React, { useState , useCallback } from 'react';

// ToggleButton component
function TogglerButton({ setValue, title }) {
  // Initialize toggle state to false
  const [isToggled, setIsToggled] = useState(false);

  // Function to handle toggle
  const toggle = useCallback(() => {
    setIsToggled((prevState) => {
      const newState = !prevState;
      setValue(newState); // Call the passed-in setValue function
      return newState;
    });
  }, [setValue]);

  
  // Styling for the toggler
  const toggleStyle = {
    cursor: 'pointer',
    width: '60px',
    height: '33px',
    borderRadius: '52px',
    backgroundColor: isToggled? 'white' : '#586ce1',
    position: 'relative',
    transition: 'background-color 0.3s',
    zIndex : '30000',
    borderStyle : 'solid',
    borderWidth: '2px',
    borderColor: '#ffffff',
  };

  const circleStyle = {
    position: 'absolute',
    top: '4px',
    left: isToggled ? '31px' : '4px',
    width: '22px',
    height: '22px',
    backgroundColor: isToggled?  '#586ce1' : 'white',
    borderRadius: '50%',
    borderWidth : '2px',
    transition: 'left 0.3s',
  };

  const imgStyle = {
    height : '60%',
    width : '65%',
    top : '-1px',
    left : '2px',
    position : 'relative'
  }

  return (
    <button style={toggleStyle} onClick={toggle} title={title}>
      <div style={circleStyle}>
        <img className='selectDisable' src='/images/glitter.svg' style={imgStyle}/>
      </div>
    </button>
  );
};

export default TogglerButton;

import 'react-bootstrap';
import 'bootstrap';
import ExplainedCard from './ExplainedCard';

function Recalibration() {
    return (
    <div>
        <div className="content region-start explained-container" id='Multicoin-Recalibration'>
            <div className=''>
                <h4 className='explained-header' >Token Streams</h4>
                <div className="flex-evenly recalibration-section" style={{alignItems: 'flex-start'}}>
                    <div className="flex-vertical recalibration-content" style={{marginLeft: '0px'}}>
                        <p className="default_text_color">
                            Token streams enable the continuous and dynamic growth of Multi's token reserve. They represent a commitment by participants, known as stream providers, to regularly contribute a specific quantity of an approved token to the reserve over a predetermined period. This process facilitates the ongoing diversification and expansion of Multi's asset backing.
                        </p>
                        <ExplainedCard text={'Anyone can participate as a stream provider.'}/>
                        <ExplainedCard text={"More efficient than traditional consensus."}/>
                        <ExplainedCard text={"Enables decentralized and scalable decision-making."}/>
                        <ExplainedCard text={"Decision-makers are held accountable."}/>
                        <ExplainedCard text={"Ensures a predictable and steady flow of tokens."}/>
                        <ExplainedCard text={"Encourages selection of high-quality tokens through competitive reward system."}/>
                        
                    </div>
                    <img src='images/token_accum.png' alt='Reserve providers pooling assets into Multi.'
                         className='content recalibration-content' style={{marginTop: 'auto', marginBottom: 'auto'}}/>
                </div>
            </div>
        </div>
        <div className='explained-white-border'>
            <div className="flex-evenly" style={{alignItems: 'flex-start'}}>
                <div className="flex-vertical recalibration-content" style={{marginLeft: '0px'}}>
                    <h5 className='explained-header explained-h5' style={{marginBottom:'8px'}}>Token Stream Contracts</h5>
                    <p className="default_text_color">
                        These contracts formalize the commitment between stream providers and Multi. They outline the terms of the token stream, including the token type, quantity, contribution schedule, and duration. The staking of governance tokens as collateral is also a key component of these contracts, ensuring accountability and incentivizing long-term participation.
                    </p>
                    
                </div>
                <img src="/images/figures/recalibration.png" alt='Reserve providers pooling assets into Multi.'
                        className='content recalibration-content' style={{marginTop: 'auto', marginBottom: 'auto'}}/>
            </div>
        </div>
    </div>

    );
}

export default Recalibration;
